import React from 'react';
import _ from 'lodash';
import { differenceInMonths, differenceInYears, format } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Stack, Link, Button, Chip, Tooltip } from '@mui/material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

import makeStyles from '@mui/styles/makeStyles';
import { Skeleton } from '@mui/material';

import LocaleFormatting from '@/helpers/LocaleFormatting';
import placeHolderProfilePhoto from './images/profile.png';
import { SimilarNamesWarning } from '@/components/SimilarNamesWarning';
import { PatientDetailsFragment, useGetDefaultCarePathwayQuery } from '@/generated/graphql';
import { PatientHeaderActionsDropdown } from '@/components/PatientHeaderActionsDropdown';
import { usePatientDischargeModal } from './patientDischarge';
import { isDefined } from '@/helpers/isDefined';
import { useAuthMe, useMeActingOrganizationFeature, useMePermission } from '@/hooks/useAuth';
import auth from '@/controllers/Auth';
import { usePatientAdmitJourney } from './Journeys/AdmitPatientJourney';
import { PatientWatchListButton } from './PatientWatchListButton';
import { ManualDataPushButton } from './EhrIntegrations/ManualDataPushButton';
import NationalIdentifierDisplay, {
  nationalIdentifierTypeToLabel,
} from './NationalIdentifierDisplay';
import { PatientAcuityDisplay } from './PatientAcuity';

export const DEFAULT_CARE_PATHWAY_QUERY = gql`
  query GetDefaultCarePathway {
    me {
      actingOrganization {
        defaultCarePathway {
          id
        }
      }
    }
  }
`;

interface PatientHeaderProps {
  patient?: PatientDetailsFragment | null;
  refresh: () => void;
}

export default function PatientHeader({ patient, refresh }: PatientHeaderProps) {
  const classes = useStyles();
  const canAdmitWardPatients = useMePermission('admit_ward_patients');
  const nationalIdentifierScheme = useMeActingOrganizationFeature('nationalIdentifierScheme', null);
  const hasPatientAcuityScoreFeature = useMeActingOrganizationFeature('patientAcuityScore', false);
  const hasNationalIdentifierScheme = nationalIdentifierScheme !== null;
  const hasOnOxygenFeature = useMeActingOrganizationFeature('onOxygenPatientFlag');

  const { showPatientDischargeModal } = usePatientDischargeModal({
    onPatientDischarged: () => refresh(),
  });

  const { startJourney: startAdmitJourney } = usePatientAdmitJourney({
    onClose: () => refresh(),
    showPatientRecordLink: false, // We're already on the patient record here
  });

  const roles = useAuthMe<string[]>('roles', []);
  const isSelfCare = roles.includes('selfCare');

  const { data: defaultCarePathway } = useGetDefaultCarePathwayQuery();

  const defaultCarePathwayId = defaultCarePathway?.me?.actingOrganization?.defaultCarePathway?.id;

  const currentAdmission = patient?.wardAdmission;

  if (!patient) {
    return (
      <div className={classes.mainContainer}>
        <div className={classes.imageContainer}>
          <img alt="User Profile" src={placeHolderProfilePhoto} />
        </div>
        <div className={classes.detailsContainer}>
          <Skeleton height={40} width={400} />
          <Skeleton height={20} width={200} />
          <Skeleton height={20} width={300} />
          <Skeleton height={20} width={250} />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.mainContainer}>
      <Stack direction="row">
        <div className={classes.imageContainer}>
          <img alt="User Profile" src={placeHolderProfilePhoto} />
        </div>
        <div className={classes.detailsContainer}>
          <Stack direction="row" alignItems="baseline" gap={1}>
            <SimilarNamesWarning patient={patient}>
              <Link
                to={`/patient/${patient.id}`}
                component={RouterLink}
                className={classes.name}
                color="primary.dark">
                <div>{`${patient.firstName} ${patient.lastName}`}</div>
              </Link>
            </SimilarNamesWarning>
            <HeaderGenderAndAge className={classes.genderAndAge} patient={patient} />
          </Stack>
          <Stack direction="row" gap={1} marginBottom={0.5} flexWrap="wrap">
            {patient.address && (
              <Stack direction="row" alignItems="center">
                <PlaceIcon fontSize="small" sx={{ color: 'grey.600' }} />
                <div
                  className={
                    classes.contactDetails
                  }>{`${patient.address.address} ${patient.address.postcode}`}</div>
              </Stack>
            )}
            <Stack direction="row" gap={1}>
              {patient.telephone && (
                <Stack direction="row" alignItems="center">
                  <PhoneIcon fontSize="small" sx={{ color: 'grey.600' }} />
                  <div className={classes.contactDetails}>{`${patient.telephone}`}</div>
                </Stack>
              )}
              {patient.selfCare?.email && (
                <Stack direction="row" alignItems="center">
                  <EmailIcon fontSize="small" sx={{ color: 'grey.600' }} />
                  <div className={classes.contactDetails}>{patient.selfCare?.email}</div>
                </Stack>
              )}
            </Stack>
          </Stack>
          {hasNationalIdentifierScheme && (
            <div className={classes.nhsNumber}>
              <div className={classes.nhsNumberLabel}>
                {nationalIdentifierTypeToLabel(nationalIdentifierScheme)}:
              </div>
              <div>
                <NationalIdentifierDisplay
                  patientId={patient.id}
                  nationalIdentifier={patient.nationalIdentifier}
                />
              </div>
            </div>
          )}
          <Stack direction="row" gap={1} flexWrap="wrap" alignItems="center">
            {patient.wardAdmission ? (
              <div>
                <span className={classes.label}>Admitted: </span>
                {LocaleFormatting.formatCheckupTimeLongWithoutWeekDay(
                  patient.wardAdmission.admittedAt,
                )}
              </div>
            ) : (
              <span className={classes.label}>No current admission</span>
            )}
            {patient.wardAdmission && (
              <Chip
                size="small"
                component={RouterLink}
                to={`/wards/${patient.wardAdmission.ward.id}`}
                target="_blank"
                className={classes.chip}
                variant="outlined"
                key={patient.wardAdmission.ward.id}
                label={
                  <>
                    <span className={classes.chipLabel}>Ward</span>{' '}
                    {patient.wardAdmission.ward.name}
                  </>
                }
              />
            )}
            {patient.wardAdmission?.carePathway && (
              <Chip
                size="small"
                component={RouterLink}
                to={`/pathways/${patient.wardAdmission.carePathway.id}`}
                className={classes.chip}
                variant="outlined"
                key={patient.wardAdmission.carePathway.id}
                label={
                  <>
                    <span className={classes.chipLabel}>Care Pathway</span>{' '}
                    {patient.wardAdmission.carePathway.name}
                  </>
                }
              />
            )}
            {hasOnOxygenFeature &&
              patient.wardAdmission &&
              patient.wardAdmission?.patientFlags
                .filter((flag) => flag.active)
                .map((flag) => (
                  <Tooltip key={flag.description} title={flag.description}>
                    <Chip
                      size="small"
                      key={flag.type}
                      label={
                        <>
                          <span className={classes.chipLabel}>{flag.title}</span>
                        </>
                      }
                    />
                  </Tooltip>
                ))}
          </Stack>
          {patient.latestContinuousMonitoring &&
            patient.latestContinuousMonitoring.continuousMonitoringSession.organization.id ===
              auth.me('actingOrganization.id') &&
            !patient.latestContinuousMonitoring.continuousMonitoringSession.endedAt && (
              <div>
                <span className={classes.label}>Continuous Monitoring Kit:</span>{' '}
                {patient.latestContinuousMonitoring?.continuousMonitoringSession.vivalinkSubjectId}
              </div>
            )}
          {patient.activityMonitoringSession &&
            patient.activityMonitoringSession.organization.id ===
              auth.me('actingOrganization.id') && (
              <div>
                <span className={classes.label}>Activity Monitoring Kit:</span>{' '}
                {patient.activityMonitoringSession?.pacsanaUserName}
              </div>
            )}
          {hasPatientAcuityScoreFeature && (
            <PatientAcuityDisplay sx={{ marginTop: 0.5 }} acuityScore={patient.latestAcuityScore} />
          )}
        </div>
      </Stack>
      {!isSelfCare ? (
        <div className={classes.patientActions}>
          <PatientWatchListButton patient={patient} refresh={refresh} />

          <ManualDataPushButton patient={patient} defaultCarePathwayId={defaultCarePathwayId} />

          {canAdmitWardPatients && (
            <>
              {isDefined(currentAdmission) ? (
                <Button
                  startIcon={<AssignmentIndIcon />}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => showPatientDischargeModal({ patient, currentAdmission })}>
                  Discharge from Ward
                </Button>
              ) : (
                <Button
                  startIcon={<AssignmentIndIcon />}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    startAdmitJourney({
                      initialJourneyState: {
                        patient: patient,
                        initializedWithPatient: true,
                      },
                    })
                  }>
                  Admit to ward
                </Button>
              )}
            </>
          )}
          <PatientHeaderActionsDropdown patient={patient} refresh={refresh} />
        </div>
      ) : null}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: theme.typography.body2.fontSize,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    borderBottom: '1px solid rgba(0,0,0,0.1)',
  },
  imageContainer: {
    display: 'none',
    '& img': {
      width: theme.spacing(8),
      height: theme.spacing(8),
      borderRadius: '50%',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
  },
  name: {
    fontSize: '24px',
  },
  genderAndAge: {
    color: theme.palette.grey[700],
    fontWeight: 500,
  },
  organization: {
    color: 'gray',
    fontWeight: 'bold',
  },
  contactDetails: {
    color: theme.palette.grey[700],
    marginLeft: theme.spacing(0.5),
  },
  nhsNumberLabel: {
    marginRight: theme.spacing(0.5),
    fontWeight: 500,
  },
  label: {
    fontWeight: 500,
  },
  nhsNumber: {
    display: 'flex',
    alignItems: 'center',
  },
  patientActions: {
    display: 'flex',
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
    flexDirection: 'row',
    margin: theme.spacing(1, 0.5, 0.5, 0),
    // add left margin to all buttons except the first one
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
  },
  chip: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  chipLabel: {
    fontWeight: 500,
  },
}));

function HeaderGenderAndAge({
  className,
  patient: { gender, birthDate },
}: {
  className: string;
  patient: PatientDetailsFragment;
}) {
  const formattedGender = _.capitalize(gender);
  const birthDateObject = new Date(birthDate);
  const formattedBirthDate = format(birthDateObject, 'dd/MM/yyyy');
  const ageYears = differenceInYears(new Date(), birthDateObject);
  const ageMonths = differenceInMonths(new Date(), birthDateObject);
  const formattedAge = isNaN(ageYears) || ageYears < 1 ? `${ageMonths} m.o.` : `${ageYears} y.o.`;

  return (
    <div className={className}>{`${formattedGender}, ${formattedBirthDate} (${formattedAge})`}</div>
  );
}
