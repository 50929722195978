import React from 'react';
import { NewMessage } from './NewMessage';
import { Stack, Typography } from '@mui/material';
import { PageContainer } from '@/components/PageContainer';
import { gql } from '@apollo/client';
import {
  PatientChatMessageViewFragment,
  useGetPatientChatMessagesQuery,
} from '@/generated/graphql';

import { MessageList } from './MessageList';

import { isDefined } from '@/helpers/isDefined';
import { useAuthMe } from '@/hooks/useAuth';
import { useWindowVisible } from '@/hooks/useWindowVisible';
import { useInterval } from '@/hooks/useInterval';
import { toast } from 'sonner';

interface PatientChatTabProps {
  patientId: string;
  patientUserId?: string;
}

export const GET_PATIENT_CHAT_MESSAGES = gql`
  fragment PatientChatMessageView on PatientChatMessage {
    id
    message
    createdAt
    createdById
  }

  fragment PatientChatParticipantView on User {
    id
    ...FormattableUser
  }

  query GetPatientChatMessages($PatientId: ID!, $sinceDate: Date) {
    getPatientChatMessages(PatientId: $PatientId, sinceDate: $sinceDate) {
      messages {
        ...PatientChatMessageView
      }
      participants {
        ...PatientChatParticipantView
      }
      lastMessageDate
      totalMessages
    }
  }
`;

function isMessageAlreadyInList(
  newMessage: PatientChatMessageViewFragment,
  existingMessages: PatientChatMessageViewFragment[],
) {
  return existingMessages.some((existingMessage) => existingMessage.id === newMessage.id);
}

export function PatientChatTab({ patientId, patientUserId }: PatientChatTabProps) {
  const windowVisible = useWindowVisible();

  const { data, fetchMore, loading } = useGetPatientChatMessagesQuery({
    variables: {
      PatientId: patientId,
    },
    onError: () => {
      toast.error('Failed to fetch patient chat messages');
    },
  });

  const messages = data?.getPatientChatMessages?.messages ?? [];
  const participants = data?.getPatientChatMessages?.participants ?? [];

  /**
   * Fetches recent messages for the patient chat
   *
   * This function is called every 5 seconds when the window is visible
   * and the patient chat tab is active. It fetches messages that have been
   * sent since the last message date in the chat and appends them to the
   * existing messages.
   */
  const handleFetchRecentMessages = () => {
    fetchMore({
      variables: {
        PatientId: patientId,
        sinceDate: data?.getPatientChatMessages.lastMessageDate ?? null,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult || fetchMoreResult.getPatientChatMessages.messages.length === 0)
          return prev;

        // Combine the existing messages with the new messages
        const updatedMessages = [
          ...prev.getPatientChatMessages.messages,
          ...fetchMoreResult.getPatientChatMessages.messages.filter(
            (newMessage) =>
              !isMessageAlreadyInList(newMessage, prev.getPatientChatMessages.messages),
          ),
        ];

        return {
          getPatientChatMessages: {
            ...fetchMoreResult.getPatientChatMessages,
            messages: updatedMessages,
          },
        };
      },
    });
  };

  useInterval(handleFetchRecentMessages, windowVisible ? 5_000 : null);

  const currentUserId = useAuthMe<string>('id', '');

  if (!isDefined(patientUserId)) {
    return (
      <PageContainer padding={6}>
        <Typography variant="h5" align="center" color="textSecondary">
          Patient does not have access to chat
        </Typography>
        <Typography align="center" color="textSecondary">
          Chat is only available to patients with a Feebris login
        </Typography>
      </PageContainer>
    );
  }

  return (
    <PageContainer
      flexGrow={1}
      display="flex"
      flexDirection="column"
      maxWidth={1080}
      paddingX={3}
      paddingY={2}>
      <Stack spacing={2} flexGrow={1} justifyContent="flex-end">
        {data?.getPatientChatMessages ? (
          <MessageList
            loading={loading}
            participants={participants}
            messages={messages}
            userId={currentUserId}
            patientUserId={patientUserId}
          />
        ) : null}
        <NewMessage patientId={patientId} onMessageSent={() => handleFetchRecentMessages()} />
      </Stack>
    </PageContainer>
  );
}
