import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Tab, Tabs } from '@mui/material';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { toast } from 'sonner';
import { Helmet } from 'react-helmet-async';

import { useGetPatientDetailsQuery } from '@/generated/graphql';

import PatientHeader from '@/components/PatientHeader';

import PatientNotesTab from './components/PatientNotesTab';
import VitalsTab from './components/VitalsTab';
import ReportsTab from './components/ReportsTab';
import CarePathwayTab from './components/CarePathwayTab';
import ActionLogTab from './components/ActionLogTab';
import PatientChatTab from './components/PatientChatTab';
import { useMeActingOrganizationFeature, useMePermission } from '@/hooks/useAuth';
import auth from '@/controllers/Auth';

export const GET_PATIENT_DETAILS = gql`
  fragment PatientAdmissionOverview on WardAdmission {
    patientId
    admittedAt
    ward {
      id
      name
    }
    carePathway {
      id
      name
      ewsThresholds {
        thresholds
      }
    }
    patientFlags {
      ...PatientFlagOverview
    }
  }

  fragment PatientFlagOverview on PatientFlag {
    type
    active
    title
    description
  }

  fragment PatientDetails on Patient {
    id
    firstName
    lastName
    numSimilarNames
    createdAt
    birthDate
    onWatchList
    gender
    address {
      address
      postcode
    }
    telephone
    organizations(type: "care_home") {
      address {
        address
        postcode
      }
    }
    selfCare {
      id
      email
      canResetPassword
      organizations {
        id
      }
      canReceivePushNotifications
    }
    nationalIdentifier {
      ...NationalIdentifierDisplay
    }
    preExistingConditions
    latestCheckup {
      endedAt
    }
    activityMonitoringSession {
      pacsanaUserName
      organization {
        id
      }
    }
    latestContinuousMonitoring {
      continuousMonitoringSession {
        endedAt
        vivalinkSubjectId
        organization {
          id
        }
      }
    }
    wardAdmission {
      ...PatientAdmissionOverview
    }
    # TODO: move this into the reports tab
    admissionEvents {
      ...AdmissionEventOverview
    }
    ewsThresholds {
      createdAt
      createdBy {
        ...FormattableUser
      }
      thresholds
    }
    wards {
      id
      name
    }
    latestAcuityScore {
      ...PatientAcuityDisplay
    }
  }

  query GetPatientDetails($patientId: ID!) {
    patient(id: $patientId) {
      ...PatientDetails
      ...DischargePatientDetails
      ...UpdatePatientView
      ...DeletePatientView
      ...SetPatientAcuityPatientDetails
    }
  }
`;

export default function PatientDetail() {
  const { patientId, tabName } = useParams<{
    patientId: string;

    tabName: 'vitals' | 'ward-discharges' | 'notes' | 'care-pathway' | 'action-log' | undefined;
  }>();
  const classes = useStyles();
  const { t } = useTranslation();
  const canViewWards = useMePermission('view_wards');
  const canViewReports = auth.isShareTokenUser() === false;
  const wardsFeature = useMeActingOrganizationFeature('wards');
  const chatFeature = useMeActingOrganizationFeature('patientChat');

  const {
    data,
    refetch: refreshPatientData,
    loading,
  } = useGetPatientDetailsQuery({
    variables: { patientId },
    onError: () => toast.error('An error occurred while loading patient details'),
  });

  return (
    <>
      <Helmet>
        <title>Patient Record</title>
      </Helmet>
      <div>
        <PatientHeader patient={data?.patient} refresh={() => refreshPatientData()} />
        <Tabs value={tabName} indicatorColor="primary" className={classes.tabs}>
          <Tab
            label={t('Vitals')}
            value="vitals"
            component={Link}
            to={`/patient/${patientId}/vitals`}
          />
          {wardsFeature && canViewWards && (
            <Tab
              label={t('Care Pathway')}
              value="care-pathway"
              component={Link}
              to={`/patient/${patientId}/care-pathway`}
            />
          )}
          {canViewReports && (
            <Tab
              label={t('Reports')}
              component={Link}
              value="reports"
              to={`/patient/${patientId}/reports`}
            />
          )}
          <Tab
            label={t('Notes')}
            value="notes"
            component={Link}
            to={`/patient/${patientId}/notes`}
          />
          {chatFeature && (
            <Tab
              label={t('Chat')}
              value="chat"
              component={Link}
              to={`/patient/${patientId}/chat`}
            />
          )}
          <Tab
            label={t('Action Log')}
            value="action-log"
            component={Link}
            to={`/patient/${patientId}/action-log`}
          />
        </Tabs>
      </div>
      <Switch>
        <Route path="/patient/:patientId/vitals">
          <VitalsTab patientId={patientId} patient={data?.patient} />
        </Route>

        <Route path="/patient/:patientId/care-pathway">
          {wardsFeature && (
            <CarePathwayTab
              isLoading={loading}
              wardAdmission={data?.patient?.wardAdmission}
              patientFirstName={data?.patient?.firstName ?? ''}
              patientLastName={data?.patient?.lastName ?? ''}
              refresh={() => refreshPatientData()}
            />
          )}
        </Route>

        <Route path="/patient/:patientId/reports">
          {data?.patient && canViewReports && <ReportsTab patient={data?.patient} />}
        </Route>

        <Route path="/patient/:patientId/notes">
          <PatientNotesTab patientId={patientId} />
        </Route>

        <Route path="/patient/:patientId/chat">
          {data?.patient && chatFeature && (
            <PatientChatTab patientId={patientId} patientUserId={data?.patient?.selfCare?.id} />
          )}
        </Route>

        <Route path="/patient/:patientId/action-log">
          <ActionLogTab patientId={patientId} />
        </Route>

        {/* Redirect preserving the query parameters - when a user is logged out but ends up here from an external redirect, the params need to survive to make it to the login screen*/}
        <Redirect to={`/patient/${patientId}/vitals` + window.location.search} />
      </Switch>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  patientDetail: {
    maxWidth: '1400px',
    margin: '0 auto',
    width: '100%',
  },
  tabs: {
    backgroundColor: theme.palette.background.paper,
  },
  actionHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap-reverse',
    marginBottom: '30px',
    padding: theme.spacing(1, 1.5),
  },
  viewSelector: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(0.5, 0),
  },
  icon: {
    marginRight: '5px',
  },
  viewText: {
    color: '#009ac9',
    marginRight: '15px',
  },
  tableAndChartsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'center',
    flex: 4,
    position: 'sticky',
    top: '80px',
    height: '100%',
  },
  vitalChartsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 3,
    borderLeft: '1px solid rgba(0,0,0,0.1)',
  },
  buttonWrap: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0.5, 0),
    // add left margin to all buttons except the first one
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
  },
}));
