import React from 'react';

import {
  SetPatientAcuityPatientDetailsFragment,
  PatientAcuityDisplayFragment,
  useSetPatientAcuityScoreMutation,
} from '@/generated/graphql';
import { gql } from '@apollo/client';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import { useFormik } from 'formik';
import { ShowFnOutput, useModal } from 'mui-modal-provider';
import * as Yup from 'yup';
import { getMutationErrors } from '@/AuthorizedApolloClientProvider';
import { muiFormikGetFieldProps } from '@/helpers/formik';
import { isDefined } from '@/helpers/isDefined';
import { makeStyles } from '@mui/styles';

export const SET_PATIENT_ACUITY_FRAGMENT = gql`
  fragment SetPatientAcuityPatientDetails on Patient {
    id
    firstName
    lastName
    latestAcuityScore {
      ...PatientAcuityDisplay
    }
  }
`;

export const SET_PATIENT_ACUITY_SCORE = gql`
  mutation SetPatientAcuityScore($patientId: ID!, $score: Int!, $reason: String) {
    setPatientAcuityScore(patientId: $patientId, score: $score, reason: $reason) {
      ...PatientAcuityDisplay
    }
  }
`;

interface SetPatientAcuityScoreModalProps extends DialogProps {
  patient: SetPatientAcuityPatientDetailsFragment;
  onAcuityScoreSet: (acuityScore: PatientAcuityDisplayFragment) => void;
  onCancel: () => void;
}

const setPatientAcuityScoreSchema = (patientName: string, currentAcuity: Maybe<number>) =>
  Yup.object().shape({
    score: Yup.number()
      .nullable()
      .required()
      .min(1, 'Must be between 1 and 4')
      .max(4, 'Must be between 1 and 4')
      .test('acuity-change', 'Score must change', (score) => score !== currentAcuity),
    reason: Yup.string().optional(),
    patientNameConfirmation: Yup.string()
      .required('Patient name must be confirmed')
      .test(
        'name-comparison',
        'Patient name must match',
        (name) =>
          name.trim().localeCompare(patientName, undefined, {
            sensitivity: 'accent',
          }) === 0,
      ),
  });

type SetPatientAcuityScoreFormValues = Yup.InferType<
  ReturnType<typeof setPatientAcuityScoreSchema>
>;

export const SetPatientAcuityScoreModal = ({
  open,
  patient,
  onAcuityScoreSet,
  onCancel,
}: SetPatientAcuityScoreModalProps) => {
  const [setPatientAcuityScore, { error: setPatientAcuityScoreError }] =
    useSetPatientAcuityScoreMutation();

  const classes = useStyles();

  const patientFullName = `${patient.firstName} ${patient.lastName}`;

  const schema = setPatientAcuityScoreSchema(patientFullName, patient.latestAcuityScore?.score);

  const formik = useFormik<SetPatientAcuityScoreFormValues>({
    initialValues: {
      score: undefined as number | undefined,
      reason: '',
      patientNameConfirmation: '',
    } as SetPatientAcuityScoreFormValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      const { data } = await setPatientAcuityScore({
        variables: {
          patientId: patient.id,
          score: Number.parseInt(values.score.toString()),
          reason: values.reason?.trim() || null,
        },
      });

      if (data?.setPatientAcuityScore) {
        onAcuityScoreSet(data.setPatientAcuityScore);
      }
    },
  });

  const { argErrors } = getMutationErrors(setPatientAcuityScoreError);
  const getFieldProps = muiFormikGetFieldProps(formik, argErrors, schema);

  const latestAcuityScore = patient.latestAcuityScore;

  const acuityChange = acuityChangeDirection(
    formik.values.score ?? 0,
    latestAcuityScore?.score ?? 0,
  );

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="sm"
      fullWidth
      aria-labelledby="patient_acuity_score-dialog-title"
      aria-describedby="patient_acuity_score-dialog-description">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="patient_acuity_score-dialog-title">
          <Stack gap={1} direction="row" alignItems="center">
            <TimelineIcon />
            Set Acuity Score for {patient.firstName} {patient.lastName}
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="patient_acuity_score-dialog-description">
            Set the acuity score for this patient. This will be used to report on acuity levels and
            changes over time.
          </DialogContentText>
          {latestAcuityScore && (
            <Alert severity="info" sx={{ marginTop: 1, marginBottom: 3 }}>
              The current acuity score is <strong>{latestAcuityScore.score}</strong>, set on{' '}
              <strong>{new Date(latestAcuityScore.createdAt).toLocaleString()}</strong> with reason:{' '}
              <strong>{latestAcuityScore.reason}</strong>
              <Typography variant="body2" marginTop={1}>
                Setting a new score will take effect immediately.
              </Typography>
            </Alert>
          )}
          <Stack gap={2} marginTop={2}>
            <Stack direction="row" gap={1} alignItems="center">
              <TextField
                variant="outlined"
                sx={{ alignSelf: 'flex-start', minWidth: 70 }}
                label="Current"
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                type="number"
                inputProps={{
                  maxlength: 1,
                  min: 0,
                  max: 5,
                  className: classes.scoreInput,
                }}
                value={latestAcuityScore?.score ?? '-'}
              />
              <TextField
                sx={{ alignSelf: 'flex-start', minWidth: 70 }}
                fullWidth={false}
                variant="outlined"
                id="score"
                name="score"
                label="Score"
                placeholder="-"
                type="number"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  maxLength: 1,
                  min: 0,
                  max: 5,
                  className: classes.scoreInput,
                }}
                {...getFieldProps('score', { fireOnChange: false })}
                onKeyDown={(e) => {
                  // ignore non-numeric keys
                  if (isNaN(parseInt(e.key))) {
                    e.preventDefault();
                    return;
                  }

                  formik.setFieldValue('score', e.key);
                }}
              />
              {isDefined(formik.values.score) && acuityChange !== null && acuityChange !== 0 ? (
                <Alert
                  aria-label="acuity change description"
                  severity={acuityChange === 1 ? 'warning' : 'success'}
                  icon={false}>
                  {acuityChange > 0 ? 'Increasing' : 'Decreasing'} acuity score by{' '}
                  <strong>{Math.abs((latestAcuityScore?.score ?? 0) - formik.values.score)}</strong>
                </Alert>
              ) : (
                <Box flex={1} />
              )}
            </Stack>
            <TextField
              fullWidth
              variant="outlined"
              multiline
              minRows={1}
              maxRows={4}
              id="reason"
              name="reason"
              label="Reason (recommended)"
              placeholder={`Provide a reason for ${
                latestAcuityScore ? 'updating' : 'setting'
              } the acuity score for this patient`}
              InputLabelProps={{ shrink: true }}
              {...getFieldProps('reason')}
            />
            <TextField
              fullWidth
              sx={{ marginTop: 2 }}
              variant="outlined"
              label="Confirm patient name"
              placeholder={patientFullName}
              name="patientNameConfirmation"
              {...getFieldProps('patientNameConfirmation', {
                defaultHelperText: "Please type the patient's full name to confirm",
              })}
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button type="submit" variant="contained" disabled={!formik.isValid}>
            Set Acuity Score
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  scoreInput: {
    textAlign: 'center',
    caretColor: 'transparent',
    fontSize: theme.typography.h6.fontSize,
    // hide the up/down arrows on number input in Chrome and Firefox
    '&::-webkit-outer-spin-button,&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    'input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
}));

/**
 * Tracks the change in acuity score
 *
 * - If it wasn't set before, it will be null
 * - If it's higher than the previous score, it will be +1
 * - If it's lower than the previous score, it will be -1
 * - If it's the same as the previous score, it will be 0
 */
function acuityChangeDirection(
  newScore: number | null,
  oldScore: number | null,
): 1 | -1 | 0 | null {
  if (oldScore === null || newScore === null) {
    return null;
  }

  if (newScore === oldScore) {
    return 0;
  }

  return newScore > oldScore ? 1 : -1;
}

type UseSetPatientAcuityScoreModalProps = Pick<SetPatientAcuityScoreModalProps, 'onAcuityScoreSet'>;

export const useSetPatientAcuityScoreModal = ({
  onAcuityScoreSet,
}: UseSetPatientAcuityScoreModalProps) => {
  const { showModal } = useModal();

  return {
    showSetPatientAcuityScoreModal: ({
      patient,
    }: // currentAcuityScore,
    Pick<SetPatientAcuityScoreModalProps, 'patient'>) => {
      const modal: ShowFnOutput<SetPatientAcuityScoreModalProps> = showModal(
        SetPatientAcuityScoreModal,
        {
          patient,
          onAcuityScoreSet(acuityScore) {
            onAcuityScoreSet(acuityScore);
            modal.hide();
          },
          onCancel: () => {
            return modal.hide();
          },
        },
        { destroyOnClose: true },
      );
    },
  };
};
